var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Accordion, AccordionSet, Button, Col, ErrorModal, Pane, PaneFooter, Row, Select, } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router";
import RecekTextField from "../../components/RecekTextField";
import { createPublisherPrefixRange, fetchPublisherPrefixRangeById, updatePublisherPrefixRange, } from "../../api/publisherPrefixRange";
import { fetchAgencyPrefixes } from "../../api/agencyPrefixesService";
function PublisherPrefixRangeForm() {
    var _this = this;
    var history = useHistory();
    var ky = useOkapiKy();
    var intl = useIntl();
    var id = useParams().id;
    var _a = useState({
        id: null,
        agencyPrefix: null,
        magnitude: 1,
        start: null,
        end: null,
        status: "ACTIVE",
        nextSequence: null,
    }), formValues = _a[0], setFormValues = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState(false), errorModalOpen = _c[0], setErrorModalOpen = _c[1];
    var _d = useState(""), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState([]), agencyPrefixesOptions = _e[0], setAgencyPrefixesOptions = _e[1];
    var _f = useState(null), agencyPrefixId = _f[0], setAgencyPrefixId = _f[1];
    var _g = useState({ general: true }), accordionStatus = _g[0], setAccordionStatus = _g[1];
    var _h = useState(false), editMode = _h[0], setEditMode = _h[1];
    useEffect(function () {
        if (id) {
            setEditMode(true);
            fetchPublisherPrefixRangeById(ky, id).then(function (data) {
                setAgencyPrefixId(data.agencyPrefix.id);
                setFormValues(data);
            });
        }
        var fetchAgencyPrefixesOption = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                fetchAgencyPrefixes(ky, {}).then(function (prefixes) {
                    setAgencyPrefixesOptions(prefixes);
                });
                return [2 /*return*/];
            });
        }); };
        fetchAgencyPrefixesOption();
    }, []);
    var getAgencyPrefixById = function (id) {
        return agencyPrefixesOptions.find(function (prefix) { return prefix.id === id; });
    };
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var validateStartComparedToEnd = function () {
        if (formValues.start && formValues.end) {
            return formValues.start >= formValues.end;
        }
        return false;
    };
    var validateNumberLengthWithMagnitude = function (number) {
        if (Number.isNaN(number) || !Number.isFinite(number)) {
            return false; // Handle cases where number is NaN or Infinity
        }
        return (number >= 0 &&
            number >= Math.pow(10, formValues.magnitude) &&
            number <= Math.pow(10, (formValues.magnitude + 1)) - 1);
    };
    var validateFormCreate = function () {
        var newErrors = {};
        if (!validateNumberLengthWithMagnitude(formValues.start)) {
            newErrors.end = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.start.length",
            });
        }
        if (!validateNumberLengthWithMagnitude(formValues.end)) {
            newErrors.end = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.end.length",
            });
        }
        if (!validateStartComparedToEnd()) {
            newErrors.start = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.end.gt.start",
            });
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var validateFormEdit = function () {
        var newErrors = {};
        if (!validateNumberLengthWithMagnitude(formValues.end)) {
            newErrors.end = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.end.length",
            });
        }
        if (!validateStartComparedToEnd()) {
            newErrors.start = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.end.gt.start",
            });
        }
        if (formValues.end < formValues.nextSequence - 1) {
            newErrors.end = intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.error.end.lt.nextSequence",
            });
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!editMode) return [3 /*break*/, 3];
                    if (!validateFormEdit()) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, updatePublisherPrefixRange(ky, formValues)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    if (!validateFormCreate()) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, createPublisherPrefixRange(ky, formValues)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    history.goBack();
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _a.sent();
                    console.error("Error when trying to save publisher prefix range:", error_1);
                    setErrorModalOpen(true);
                    setErrorMessage(intl.formatMessage({
                        id: "ui-br-agency.general.server.error.message",
                    }) +
                        " : " +
                        error_1.message);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit },
            React.createElement(FormattedMessage, { id: "ui-br-agency.button.save" })), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } },
            React.createElement(FormattedMessage, { id: "ui-br-agency.button.cancel" })) }));
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: intl.formatMessage({
                id: editMode
                    ? "ui-br-agency.publisher-prefix-range.pane.label.edit"
                    : "ui-br-agency.publisher-prefix-range.pane.label.create",
            }), footer: footer },
            React.createElement(ErrorModal, { id: "error-modal", open: errorModalOpen, label: intl.formatMessage({ id: "ui-br-agency.modal.label.error" }), message: errorMessage, onClose: function () { } }),
            React.createElement("form", { onSubmit: handleSubmit }),
            React.createElement(AccordionSet, null,
                React.createElement(Accordion, { label: "Rozsah prefix\u016F", open: accordionStatus.general, onToggle: function () {
                        return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                    } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Select, { label: intl.formatMessage({
                                    id: "ui-br-agency.publisher-prefix-range.agency.prefix",
                                }), name: "agency", value: agencyPrefixId, disabled: editMode, onChange: function (e) {
                                    formValues.agencyPrefix = getAgencyPrefixById(e.target.value);
                                    setAgencyPrefixId(e.target.value);
                                }, required: true },
                                React.createElement("option", { value: undefined }, " "),
                                agencyPrefixesOptions.map(function (option) { return (React.createElement("option", { key: option.id, value: option.id },
                                    option.agency.toString(),
                                    " ",
                                    option.value)); })))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 }, !editMode && (React.createElement(Select, { label: "Po\u010Det \u010D\u00EDsel", name: "magnitude", value: formValues.magnitude || null, onChange: handleInputChange, required: true },
                            React.createElement("option", { value: 1 }, "10"),
                            React.createElement("option", { value: 2 }, "100"),
                            React.createElement("option", { value: 3 }, "10 000"),
                            React.createElement("option", { value: 4 }, "100 000"))))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RecekTextField, { label: "Zacatek", name: "start", value: formValues.start, onChange: handleInputChange, disabled: editMode, error: errors.start, required: true }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(RecekTextField, { label: "Konec", name: "end", value: formValues.end, onChange: handleInputChange, error: errors.end, required: true }))))))));
}
export default PublisherPrefixRangeForm;
