import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import OrganizationList from "./OrganizationList";
import OrganizationCreate from "./OrganizationCreate";
import OrganizationEdit from "./OrganizationEdit";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
function Organization() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, render: function () { return React.createElement(OrganizationList, null); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(OrganizationCreate, null)) : (React.createElement(Redirect, { to: "brusers/my-profile" }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(OrganizationEdit, null)) : (React.createElement(Redirect, { to: "brusers/my-profile" }));
            } })));
}
export default Organization;
