var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getHeaders } from "../utils/apiUtils";
// API functions
export var fetchRegions = function (ky) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("regions", { headers: getHeaders() })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.regions];
        }
    });
}); };
export var fetchAddress = function (ky, query) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publisher-address/suggest", {
                    searchParams: { address: query, limit: 10 },
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.publisherAddresses];
        }
    });
}); };
export var fetchAddressCode = function (ky, addressCode) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publisher-address/".concat(addressCode), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
        }
    });
}); };
export var createPublisher = function (ky, publisherData) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.post("publishers", {
                    json: publisherData,
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Error: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
export var fetchPublisherData = function (id, ky) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publishers/".concat(id), { headers: getHeaders() })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Error: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
export var fetchPublishers = function (ky, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var name, companyNumber, startDate, endDate, status, queryParts, statusQuery, queryParam, response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                name = filters.name, companyNumber = filters.companyNumber, startDate = filters.startDate, endDate = filters.endDate, status = filters.status;
                queryParts = [];
                if (name)
                    queryParts.push("name=\"".concat(name, "*\""));
                if (companyNumber)
                    queryParts.push("companyNumber=\"".concat(companyNumber, "\""));
                if (startDate)
                    queryParts.push("birthDate>=\"".concat(new Date(startDate).toISOString().split(".")[0], "\""));
                if (endDate)
                    queryParts.push("birthDate<=\"".concat(new Date(endDate).toISOString().split(".")[0], "\""));
                if (status.length > 0) {
                    statusQuery = status
                        .map(function (s) { return "status==\"".concat(s, "\""); })
                        .join(" or ");
                    queryParts.push("".concat(statusQuery));
                }
                queryParam = queryParts.length > 0
                    ? "query=".concat(encodeURIComponent(queryParts.join(" and ")))
                    : "";
                return [4 /*yield*/, ky.get("publishers?".concat(queryParam), {
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Fetch error: ".concat(response.statusText));
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.publishers];
        }
    });
}); };
export var searchPublishers = function (ky, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var search, name, companyNumber, birthDateFrom, birthDateTo, status, searchParams, response, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                search = filters.search, name = filters.name, companyNumber = filters.companyNumber, birthDateFrom = filters.birthDateFrom, birthDateTo = filters.birthDateTo, status = filters.status;
                searchParams = new URLSearchParams();
                if (search)
                    searchParams.append("search", search);
                if (name)
                    searchParams.append("name", name);
                if (companyNumber)
                    searchParams.append("companyNumber", companyNumber);
                if (birthDateFrom)
                    searchParams.append("birthDateFrom", new Date(birthDateFrom).toISOString().split(".")[0]);
                if (birthDateTo)
                    searchParams.append("birthDateTo", new Date(birthDateTo).toISOString().split(".")[0]);
                if (status && status.length > 0) {
                    status.forEach(function (s) { return searchParams.append("status", s); });
                }
                return [4 /*yield*/, ky.get("publishers/search", {
                        headers: getHeaders(),
                        searchParams: searchParams,
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Fetch error: ".concat(response.statusText));
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.publishers];
        }
    });
}); };
export var updatePublisher = function (ky, id, formValues) { return __awaiter(void 0, void 0, void 0, function () {
    var jsonData, response;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                jsonData = __assign(__assign({}, formValues), { version: formValues.version, mainAddress: formValues.mainAddress || undefined, mailingAddress: formValues.mailingAddress || undefined, alternativeNames: ((_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.map(function (altName) { return ({
                        name: altName.name,
                        nameSuffix: altName.nameSuffix,
                        type: altName.type,
                        nonPublic: altName.nonPublic,
                        publisher: altName.publisher,
                    }); })) || [], contacts: ((_b = formValues.contacts) === null || _b === void 0 ? void 0 : _b.map(function (contact) { return ({
                        type: contact.type,
                        value: contact.value,
                    }); })) || [], status: formValues.status || undefined, guarantors: ((_c = formValues.guarantors) === null || _c === void 0 ? void 0 : _c.map(function (guarantor) { return (__assign({}, guarantor)); })) || [] });
                return [4 /*yield*/, ky.put("publishers/".concat(id), {
                        json: jsonData,
                        headers: getHeaders(),
                    })];
            case 1:
                response = _d.sent();
                if (!response.ok)
                    throw new Error("Error: ".concat(response.statusText));
                return [2 /*return*/, response];
        }
    });
}); };
export var lookupPublisherByCompanyNumber = function (ky, companyNumber) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publishers/".concat(companyNumber, "/lookup"), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Failed to lookup publisher in ares");
                return [2 /*return*/, response.json()];
        }
    });
}); };
export var closePublisher = function (ky, publisherId, note) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.post("publishers/".concat(publisherId, "/close"), {
                    json: { internalComment: note },
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Failed to close publisher");
                return [2 /*return*/, response];
        }
    });
}); };
export var updatePublisherAction = function (ky, publisherId, action) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.post("publishers/".concat(publisherId, "/").concat(action), {
                    headers: getHeaders(),
                })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Failed to perform action: ".concat(action));
                return [2 /*return*/, response];
        }
    });
}); };
