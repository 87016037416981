var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// Reusable function to get headers
var getHeaders = function () { return ({
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    Expires: "0",
}); };
export var fetchUsers = function (ky, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var search, organization, archived, sortBy, queryParts, searchParam, sortParam, queryString, response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                search = filters.search, organization = filters.organization, archived = filters.archived, sortBy = filters.sortBy;
                queryParts = [];
                if (search)
                    queryParts.push("search=".concat(search));
                if (organization)
                    queryParts.push("organization=".concat(organization));
                if (archived !== undefined)
                    queryParts.push("archived=".concat(archived));
                searchParam = queryParts.length > 0 ? "".concat(queryParts.join("&")) : "";
                sortParam = sortBy ? "sortBy=".concat(sortBy) : "";
                queryString = [searchParam, sortParam]
                    .filter(function (param) { return param; })
                    .join("&");
                return [4 /*yield*/, ky.get("users?".concat(queryString), {
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.users];
            case 3:
                error_1 = _a.sent();
                console.error("Fetch error:", error_1);
                throw error_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var changeUserPassword = function (ky, userId, passwords) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("users/change-current-password", {
                        json: passwords,
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Failed to change password");
                }
                console.log("Password changed successfully");
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                console.error("Failed to change password:", error_2);
                throw error_2;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var selectOrganization = function (ky, organizationId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("bl-users/select-organization", {
                        json: { organizationId: organizationId },
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Failed to select organization");
                }
                console.log("Organization selected successfully");
                return [3 /*break*/, 3];
            case 2:
                error_3 = _a.sent();
                console.error("Failed to select organization:", error_3);
                throw error_3;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchCurrentUser = function (ky) { return __awaiter(void 0, void 0, void 0, function () {
    var response, data, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, ky.get("bl-users/_self", {
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3:
                error_4 = _a.sent();
                console.error("Error fetching current user:", error_4);
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); };
// Example usage of the updated fetchCurrentUser function
(function () { return __awaiter(void 0, void 0, void 0, function () {
    var kyInstance, currentUser;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                kyInstance = {};
                return [4 /*yield*/, fetchCurrentUser(kyInstance)];
            case 1:
                currentUser = _a.sent();
                if (currentUser) {
                    console.log("User ID:", currentUser.user.id);
                    console.log("User Permissions:", currentUser.permissions.permissions);
                    console.log("Active Organization:", currentUser.activeOrganization);
                }
                return [2 /*return*/];
        }
    });
}); })();
// Consolidated function to either archive or restore a user organization
export var manageUserOrganization = function (ky, userUuid, orgUuid, action) { return __awaiter(void 0, void 0, void 0, function () {
    var endpoint, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                endpoint = "users/".concat(userUuid, "/organizations/").concat(orgUuid, "/").concat(action);
                return [4 /*yield*/, ky.post(endpoint, {
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                console.log("".concat(action.charAt(0).toUpperCase() + action.slice(1), " operation successful"));
                return [3 /*break*/, 3];
            case 2:
                error_5 = _a.sent();
                console.error("Error ".concat(action, " organization association for user ").concat(userUuid, ":"), error_5);
                throw error_5;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateUser = function (ky, userId, updates) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.put("users/".concat(userId), {
                        json: updates,
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_6 = _a.sent();
                console.error("Error updating user with ID ".concat(userId, ":"), error_6);
                throw error_6;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchUserOrganizations = function (ky, userId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky
                        .get("users/".concat(userId, "/organizations"), {
                        headers: getHeaders(),
                    })
                        .json()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.userOrganizations || []];
            case 2:
                error_7 = _a.sent();
                console.error("Error fetching organizations for user ".concat(userId, ":"), error_7);
                throw error_7;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var fetchUserById = function (ky, userId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, user, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, ky.get("users/".concat(userId), {
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                user = _a.sent();
                return [2 /*return*/, user];
            case 3:
                error_8 = _a.sent();
                console.error("Error fetching user with ID ".concat(userId, ":"), error_8);
                throw error_8;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var createUser = function (ky, user) { return __awaiter(void 0, void 0, void 0, function () {
    var response, newUser, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, ky.post("users", {
                        json: user,
                        headers: getHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                newUser = _a.sent();
                return [2 /*return*/, newUser];
            case 3:
                error_9 = _a.sent();
                console.error("Error creating user:", error_9);
                throw error_9;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var assignUserToOrganization = function (ky, userUuid, orgUuid, roles) { return __awaiter(void 0, void 0, void 0, function () {
    var error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.put("users/".concat(userUuid, "/organizations/").concat(orgUuid), {
                        json: { roles: roles },
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_10 = _a.sent();
                console.error("Error assigning user to organization:", error_10);
                throw error_10;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var removeUserFromOrganization = function (ky, userUuid, orgUuid) { return __awaiter(void 0, void 0, void 0, function () {
    var error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.delete("users/".concat(userUuid, "/organizations/").concat(orgUuid), {
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_11 = _a.sent();
                console.error("Error removing user from organization:", error_11);
                throw error_11;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var logout = function (ky) { return __awaiter(void 0, void 0, void 0, function () {
    var error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("authn/logout", {
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                console.log("Logout successful");
                return [3 /*break*/, 3];
            case 2:
                error_12 = _a.sent();
                console.error("Error during logout:", error_12);
                throw error_12;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var resetPasswordAdmin = function (ky, userUuid, newPassword, sendEmail) { return __awaiter(void 0, void 0, void 0, function () {
    var error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("users/".concat(userUuid, "/reset-password"), {
                        json: { newPassword: newPassword, sendEmail: sendEmail },
                        headers: getHeaders(),
                    })];
            case 1:
                _a.sent();
                return [3 /*break*/, 3];
            case 2:
                error_13 = _a.sent();
                console.error("Error resetting password for user ".concat(userUuid, ":"), error_13);
                throw error_13;
            case 3: return [2 /*return*/];
        }
    });
}); };
