import React from "react";
import { ButtonGroup, Button } from "@folio/stripes/components";
import { useHistory, useLocation } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { hasRole, useActiveRoles } from "../utils/permissionsUtils";
import { RoleEnum } from "../types/RoleEnum";
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    var okapiKy = useOkapiKy();
    var activeRoles = useActiveRoles(okapiKy);
    // Define your routes
    var routes = [
        {
            path: "/brusers/my-profile",
            label: "Můj profil",
            requiredRole: RoleEnum.NONE,
        },
        {
            path: "/brusers/organizations",
            label: "Organizace",
            requiredRole: RoleEnum.AGENCY_WORKER,
        },
        {
            path: "/brusers/users",
            label: "Uživatelé",
            requiredRole: RoleEnum.AGENCY_WORKER,
        },
    ];
    // Determine the active route
    var currentPath = location.pathname;
    return (React.createElement(ButtonGroup, { style: { marginBottom: "1rem" }, fullWidth: true }, routes.map(function (route) {
        var isActive = currentPath.startsWith(route.path);
        return (React.createElement(React.Fragment, null, hasRole(activeRoles, route.requiredRole) && (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, style: {
                backgroundColor: isActive ? "#1960a4" : "",
                color: isActive ? "#fff" : "",
                fontWeight: isActive ? "bold" : "",
            } }, route.label))));
    })));
};
export default NavigationComponent;
