var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback, useState } from "react";
import { Pane, Select, Button, Row, Col, AccordionSet, Accordion, PaneFooter, ErrorModal, Selection, } from "@folio/stripes/components";
import { useHistory } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { debounce } from "lodash";
import { CollectionType } from "../../types/Collection";
import RecekTextField from "../../components/RecekTextField";
import { createCollection } from "../../api/collectionsService";
var CollectionCreate = function () {
    var _a;
    var history = useHistory();
    var ky = useOkapiKy();
    var _b = useState({
        type: "",
        publisher: undefined,
        issn: "",
        mainName: "",
        otherLanguageNames: [],
    }), formValues = _b[0], setFormValues = _b[1];
    var _c = useState({
        general: true,
        otherLanguageNames: true,
    }), accordionStatus = _c[0], setAccordionStatus = _c[1];
    var _d = useState(false), errorModalOpen = _d[0], setErrorModalOpen = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState({}), errors = _f[0], setErrors = _f[1];
    var _g = useState(true), expandAll = _g[0], setExpandAll = _g[1];
    var _h = useState(false), publisherLoading = _h[0], setPublisherLoading = _h[1];
    var _j = useState([]), publishers = _j[0], setPublishers = _j[1];
    // Debounced fetching of publishers
    var debouncedFetchPublishers = useCallback(debounce(function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setPublisherLoading(true);
            try {
            }
            catch (error) {
                console.error("Error fetching publishers:", error);
            }
            finally {
                setPublisherLoading(false);
            }
            return [2 /*return*/];
        });
    }); }, 300), [ky]);
    var validateForm = function () {
        var newErrors = {};
        if (!formValues.type) {
            newErrors.type = "Typ je povinný.";
        }
        if (!formValues.mainName) {
            newErrors.mainName = "Hlavní název je povinný.";
        }
        if (formValues.type === CollectionType.PUBLISHER_SERIES &&
            !formValues.publisher) {
            newErrors.publisher = "Nakladatel je povinný pro typ Edice.";
        }
        formValues.otherLanguageNames.forEach(function (name, index) {
            if (!name.name) {
                newErrors["otherLanguageNames[".concat(index, "]")] =
                    "N\u00E1zev v jin\u00E9m jazyce na \u0159\u00E1dku ".concat(index + 1, " je povinn\u00FD.");
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddLanguageName = function () {
        setFormValues(__assign(__assign({}, formValues), { otherLanguageNames: __spreadArray(__spreadArray([], formValues.otherLanguageNames, true), [
                { id: "", name: "" },
            ], false) }));
    };
    var handleRemoveLanguageName = function (index) {
        var updatedNames = formValues.otherLanguageNames.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { otherLanguageNames: updatedNames }));
    };
    var handleLanguageNameChange = function (index, value) {
        var updatedNames = formValues.otherLanguageNames.map(function (name, i) {
            return i === index ? __assign(__assign({}, name), { name: value }) : name;
        });
        setFormValues(__assign(__assign({}, formValues), { otherLanguageNames: updatedNames }));
    };
    var handlePublisherInput = function (inputValue) {
        debouncedFetchPublishers(inputValue);
    };
    var handlePublisherSelection = function (selectedValue) {
        var selectedPublisher = publishers.find(function (publisher) { return publisher.id === selectedValue; });
        setFormValues(__assign(__assign({}, formValues), { publisher: selectedPublisher }));
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var newCollection, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        setErrorMessage("Prosím opravte chyby ve formuláři.");
                        setErrorModalOpen(true);
                        return [2 /*return*/];
                    }
                    newCollection = {
                        type: formValues.type,
                        publisher: formValues.publisher,
                        issn: formValues.issn,
                        mainName: formValues.mainName,
                        otherLanguageNames: formValues.otherLanguageNames,
                        version: 1,
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createCollection(ky, newCollection)];
                case 2:
                    _a.sent();
                    history.goBack();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error creating collection:", error_1);
                    setErrorMessage("Došlo k chybě při vytváření kolekce.");
                    setErrorModalOpen(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                general: false,
                otherLanguageNames: false,
            });
        }
        else {
            setAccordionStatus({
                general: true,
                otherLanguageNames: true,
            });
        }
        setExpandAll(!expandAll);
    };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); } }, "Zru\u0161it") }));
    return (React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: "Vytvo\u0159en\u00ED kolekce", footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: "flex", justifyContent: "flex-end" } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? "Sbalit všechny" : "Rozbalit všechny"))),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Typ", name: "type", value: formValues.type || "", onChange: handleInputChange, required: true, placeholder: "Vyberte typ", error: errors.type },
                                    React.createElement("option", { value: CollectionType.PUBLISHER_SERIES }, "Edice"),
                                    React.createElement("option", { value: CollectionType.SERIES }, "S\u00E9rie"))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Nakladatel", name: "publisher", value: ((_a = formValues.publisher) === null || _a === void 0 ? void 0 : _a.id) || "", onChange: handlePublisherSelection, asyncFilter: true, onFilter: handlePublisherInput, dataOptions: Array.isArray(publishers)
                                        ? publishers.map(function (publisher) { return ({
                                            value: publisher.id,
                                            label: publisher.name,
                                        }); })
                                        : [], error: errors.publisher }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "ISSN", name: "issn", value: formValues.issn, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(RecekTextField, { label: "Hlavn\u00ED n\u00E1zev", name: "mainName", value: formValues.mainName, onChange: handleInputChange, required: true, error: errors.mainName })))),
                    React.createElement(Accordion, { label: "N\u00E1zvy v jin\u00E9m jazyce", open: accordionStatus.otherLanguageNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { otherLanguageNames: !accordionStatus.otherLanguageNames }));
                        } },
                        formValues.otherLanguageNames.map(function (name, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 10 },
                                React.createElement(RecekTextField, { label: "N\u00E1zev", value: name.name, onChange: function (e) {
                                        return handleLanguageNameChange(index, e.target.value);
                                    }, error: errors["otherLanguageNames[".concat(index, "]")] })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return handleRemoveLanguageName(index); }, style: { marginTop: "25px" } }, "Odstranit")))); }),
                        React.createElement(Button, { onClick: handleAddLanguageName }, "P\u0159idat n\u00E1zev")))))));
};
export default CollectionCreate;
