var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Accordion, AccordionSet, Button, Checkbox, ConfirmationModal, MenuSection, Pane, Select, } from "@folio/stripes/components";
import { FormattedMessage, useIntl } from "react-intl";
import { StripesConnectedSource } from "@folio/stripes/smart-components";
import NavComponent from "../../components/NavComponent";
import TableComponent from "../../components/TableComponent";
import { closePublisherPrefixRange, deletePublisherPrefixRange, fetchPublisherPrefixRanges, openPublisherPrefixRange, } from "../../api/publisherPrefixRange";
import { fetchAgencyPrefixes } from "../../api/agencyPrefixesService";
import PublisherPrefixRangeDetail from "./PublisherPrefixRangeDetail";
var PublisherPrefixRangeList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var intl = useIntl();
    var _a = useState(false), showConfirmModal = _a[0], setShowConfirmModal = _a[1];
    var _b = useState(null), confirmAction = _b[0], setConfirmAction = _b[1];
    var _c = useState(""), modalMessage = _c[0], setModalMessage = _c[1];
    var _d = useState(true), filterPaneIsVisible = _d[0], setFilterPaneIsVisible = _d[1];
    var _e = useState([]), agencyPrefixesOptions = _e[0], setAgencyPrefixesOptions = _e[1];
    var _f = useState(null), agencyPrefixFilter = _f[0], setAgencyPrefixFilter = _f[1];
    var _g = useState(false), showClosedFilter = _g[0], setShowClosedFilter = _g[1];
    var _h = useState(null), selectedPublisherPrefix = _h[0], setSelectedPublisherPrefix = _h[1];
    var _j = useState({
        agencyPrefix: null,
        showClosed: false,
    }), filters = _j[0], setFilters = _j[1];
    useEffect(function () {
        var fetchAgencyPrefixesOption = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                fetchAgencyPrefixes(ky, {}).then(function (prefixes) {
                    setAgencyPrefixesOptions(prefixes);
                });
                return [2 /*return*/];
            });
        }); };
        fetchAgencyPrefixesOption();
    }, []);
    var getAgencyPrefixById = function (id) {
        return agencyPrefixesOptions.find(function (prefix) { return prefix.id === id; });
    };
    var handleFilterApply = function () {
        console.log("handleFilterApply", agencyPrefixFilter, showClosedFilter);
        setFilters({
            agencyPrefix: getAgencyPrefixById(agencyPrefixFilter),
            showClosed: showClosedFilter,
        });
    };
    var handleClearFilters = function () {
        setAgencyPrefixFilter(null);
        setShowClosedFilter(false);
        setFilters({
            agencyPrefix: null,
            showClosed: false,
        });
    };
    var openConfirmModalDelete = function (prefix) {
        setConfirmAction(function () { return function () { return handleDeletePrefix(prefix.id); }; });
        setModalMessage("Opravdu chcete smazat prefix nakladatele?");
        setShowConfirmModal(true);
    };
    var handleOpenPrefix = function (prefixId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, openPublisherPrefixRange(ky, prefixId)];
                case 1:
                    _a.sent();
                    queryClient.invalidateQueries("publisher-prefix-ranges");
                    setShowConfirmModal(false);
                    setSelectedPublisherPrefix(null);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error opening publisher prefix range:", error_1);
                    setModalMessage("Nepodařilo se otevřít.");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleClosePrefix = function (prefixId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, closePublisherPrefixRange(ky, prefixId)];
                case 1:
                    _a.sent();
                    queryClient.invalidateQueries("publisher-prefix-ranges");
                    setShowConfirmModal(false);
                    setSelectedPublisherPrefix(null);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error closing publisher prefix range:", error_2);
                    setModalMessage("Nepodařilo se zavřít.");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var openConfirmModalToggle = function (prefix) {
        var isActive = prefix.status === "ACTIVE";
        var action = isActive ? handleClosePrefix : handleOpenPrefix;
        var actionText = isActive ? "zavřít" : "otevřít";
        setConfirmAction(function () { return function () { return action(prefix.id); }; });
        setModalMessage("Opravdu chcete ".concat(actionText, " prefix nakladatele?"));
        setShowConfirmModal(true);
    };
    var handleDeletePrefix = function (prefixId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, deletePublisherPrefixRange(ky, prefixId)];
                case 1:
                    _a.sent();
                    queryClient.invalidateQueries("agency-prefixes");
                    setShowConfirmModal(false);
                    setSelectedPublisherPrefix(null);
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    console.error("Error deleting agency prefix:", error_3);
                    setModalMessage("Nepodařilo se smazat.");
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var isAnyFilterActive = function () {
        return true;
    };
    var getAgencyPrefixCellRenderer = function (item) {
        if (item.agencyPrefix) {
            return "".concat(item.agencyPrefix.agency, " ").concat(item.agencyPrefix.value);
        }
        return "";
    };
    var _k = useQuery(["publisher-prefix-ranges", filters], function () {
        return fetchPublisherPrefixRanges(ky, __assign(__assign({}, filters), { agencyPrefix: filters.agencyPrefix, showClosed: filters.showClosed }));
    }, {
        enabled: true,
        staleTime: 0,
        cacheTime: 0,
    }), _l = _k.data, publisherPrefixRangesData = _l === void 0 ? [] : _l, isLoading = _k.isLoading, isError = _k.isError, refetch = _k.refetch;
    var columns = useMemo(function () { return [
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.agency.prefix",
            }),
            key: "agencyPrefix",
            cellRenderer: function (item) { return getAgencyPrefixCellRenderer(item); },
            clickable: true,
            onClick: function (item) {
                var _a;
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete("detail");
                currentParams.set("detail", (_a = item.id) !== null && _a !== void 0 ? _a : "");
                history.push({ search: currentParams.toString() });
                setSelectedPublisherPrefix(item);
            },
        },
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.column.magnitude",
            }),
            key: "magnitude",
        },
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.column.start",
            }),
            key: "start",
        },
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.column.end",
            }),
            key: "end",
        },
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.column.status",
            }),
            key: "status",
        },
        {
            name: intl.formatMessage({
                id: "ui-br-agency.publisher-prefix-range.column.nextSequence",
            }),
            key: "nextSequence",
        },
    ]; }, [location.search, history]);
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 0,
                cacheTime: 0,
                refetchOnWindowFocus: false,
                refetchOnMount: true,
            },
        },
    });
    // _rfct: Duplicity
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return publisherPrefixRangesData; };
    source.resultCount = function () { return publisherPrefixRangesData.length; };
    source.totalCount = function () { return publisherPrefixRangesData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            showConfirmModal && (React.createElement(ConfirmationModal, { open: showConfirmModal, heading: "Potvr\u010Fte akci", message: modalMessage, onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!confirmAction) return [3 /*break*/, 2];
                                return [4 /*yield*/, confirmAction()];
                            case 1:
                                _a.sent();
                                refetch();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setShowConfirmModal(false); }, confirmLabel: "Potvrdit", cancelLabel: "Zru\u0161it", buttonStyle: "primary" })),
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: intl.formatMessage({
                    id: "ui-br-agency.pane.title.navigation.and.filtering",
                }) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", marginBottom0: true, onClick: handleClearFilters }, "Resetovat v\u0161echno"),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: intl.formatMessage({
                                id: "ui-br-agency.publisher-prefix-range.accordion.title.filter",
                            }), separator: false },
                            React.createElement(Select, { label: intl.formatMessage({
                                    id: "ui-br-agency.publisher-prefix-range.agency.prefix",
                                }), name: "agency", value: agencyPrefixFilter, onChange: function (e) {
                                    setAgencyPrefixFilter(e.target.value);
                                    // handleFilterApply();
                                }, required: true },
                                React.createElement("option", { value: undefined }, " "),
                                agencyPrefixesOptions.map(function (option) { return (React.createElement("option", { key: option.id, value: option.id },
                                    option.agency.toString(),
                                    " ",
                                    option.value)); })),
                            React.createElement(Checkbox, { label: intl.formatMessage({
                                    id: "ui-br-agency.publisher-prefix-range.show.closed",
                                }), name: "showClosed", checked: showClosedFilter, onChange: function (e) {
                                    setShowClosedFilter(e.target.checked);
                                    // handleFilterApply();
                                } }),
                            React.createElement(Button, { buttonStyle: "primary", marginBottom0: true, onClick: handleFilterApply }, intl.formatMessage({ id: "ui-br-agency.button.apply" }))))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: intl.formatMessage({
                    id: "ui-br-agency.publisher-prefix-range.pane.title.list",
                }), actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push("/bragency/publisher-prefix-ranges/create");
                            } },
                            React.createElement(FormattedMessage, { id: "ui-br-agency.button.create" }))));
                } },
                React.createElement(TableComponent, { data: publisherPrefixRangesData, columns: columns, pageSize: 30, searchTerm: "", hasDataFetched: function () { return true; }, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedPublisherPrefix && (React.createElement(Pane, { defaultWidth: "35%", paneTitle: intl.formatMessage({
                    id: "ui-br-agency.publisher-prefix-range.pane.title.detail",
                }), onClose: function () {
                    setSelectedPublisherPrefix(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() });
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return history.push("/bragency/publisher-prefix-ranges/edit/".concat(selectedPublisherPrefix.id));
                            }, fullWidth: true },
                            React.createElement(FormattedMessage, { id: "ui-br-agency.button.edit" })),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return openConfirmModalDelete(selectedPublisherPrefix);
                            }, fullWidth: true },
                            React.createElement(FormattedMessage, { id: "ui-br-agency.button.delete" })),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return openConfirmModalToggle(selectedPublisherPrefix);
                            }, fullWidth: true }, selectedPublisherPrefix.status === "ACTIVE"
                            ? intl.formatMessage({ id: "ui-br-agency.button.close" })
                            : intl.formatMessage({ id: "ui-br-agency.button.open" }))));
                } },
                React.createElement(PublisherPrefixRangeDetail, { prefixRange: selectedPublisherPrefix }))))));
};
export default PublisherPrefixRangeList;
