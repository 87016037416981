import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Routes
import { useOkapiKy } from "@folio/stripes/core";
import Settings from "./settings";
import MyProfile from "./routes/my-profile/MyProfile.tsx";
import Organization from "./routes/organization/Organization.tsx";
import Users from "./routes/users/Users.tsx";
import AuditLogs from "./routes/audit-logs/AuditLogsList.tsx";
import { RoleEnum } from "./types/RoleEnum";
import { hasRole, useActiveRoles } from "./utils/permissionsUtils";

const BrUsers = (props) => {
  const okapiKy = useOkapiKy();
  const activeRoles = useActiveRoles(okapiKy);

  const {
    showSettings,
    match: { path },
  } = props;

  if (showSettings) {
    return <Settings {...props} />;
  }

  return (
    <Switch>
      <Route
        path={`${path}/audit-logs`}
        render={() =>
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <AuditLogs />
          ) : (
            <Redirect to={`${path}/my-profile`} />
          )
        }
      />
      <Route path={`${path}/my-profile`} render={() => <MyProfile />} />
      <Route
        path={`${path}/users`}
        exact
        render={() =>
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Users />
          ) : (
            <Redirect to={`${path}/my-profile`} />
          )
        }
      />
      <Route
        path={`${path}/users/*`}
        exact
        render={() =>
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Users />
          ) : (
            <Redirect to={`${path}/my-profile`} />
          )
        }
      />
      <Route
        path={`${path}/organizations`}
        exact
        render={() =>
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Organization />
          ) : (
            <Redirect to={`${path}/my-profile`} />
          )
        }
      />
      <Route
        path={`${path}/organizations/*`}
        exact
        render={() =>
          hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (
            <Organization />
          ) : (
            <Redirect to={`${path}/my-profile`} />
          )
        }
      />
      {/* Add a route for nested routes under /publishers */}
      <Route path={`${path}/my-profile/*`} component={MyProfile} />
      {/* Default Redirect */}
      <Route render={() => <Redirect to={`${path}/my-profile`} />} />
    </Switch>
  );
};

export default BrUsers;
