import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import UsersList from "./UsersList";
import UserCreate from "./UserCreate";
import UserEdit from "./UserEdit";
import UserReset from "./UserReset";
import { RoleEnum } from "../../types/RoleEnum";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
function Users() {
    var okapiKy = useOkapiKy();
    var activeRoles = useActiveRoles(okapiKy);
    var path = useRouteMatch().path;
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, render: function () { return React.createElement(UsersList, null); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(UserCreate, null)) : (React.createElement(Redirect, { to: "brusers/my-profile" }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(UserEdit, null)) : (React.createElement(Redirect, { to: "brusers/my-profile" }));
            } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/reset/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(UserReset, null)) : (React.createElement(Redirect, { to: "brusers/my-profile" }));
            } })));
}
export default Users;
