import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PublisherPrefixRangeList from "./PublisherPrefixRangeList";
import PublisherPrefixRangeForm from "./PublisherPrefixRangeForm";
function PublisherPrefixRanges() {
    var path = useRouteMatch().path;
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: PublisherPrefixRangeList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: PublisherPrefixRangeForm }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), component: PublisherPrefixRangeForm })));
}
export default PublisherPrefixRanges;
