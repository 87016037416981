import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useOkapiKy } from "@folio/stripes/core";
import { Redirect } from "react-router";
import AresUpdatesList from "./AresUpdatesList";
import AresUpdatesEdit from "./AresUpdatesEdit";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
function AresUpdates() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var activeRoles = useActiveRoles(useOkapiKy());
    var cleanPath = path.replace("/*", "");
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, render: function () { return React.createElement(AresUpdatesList, null); } }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), render: function () {
                return hasRole(activeRoles, RoleEnum.AGENCY_WORKER) ? (React.createElement(AresUpdatesEdit, null)) : (React.createElement(Redirect, { to: "/brpublishers" }));
            } })));
}
export default AresUpdates;
