import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, QueryClient, QueryClientProvider, } from "react-query";
import { useOkapiKy } from "@folio/stripes/core";
import { Button, Pane, MenuSection, IconButton, } from "@folio/stripes/components";
import { StripesConnectedSource, } from "@folio/stripes/smart-components";
import TableComponent from "../../components/TableComponent";
import NavComponent from "../../components/NavComponent";
import PublisherDetail from "../publishers/PublisherDetail"; // Adjusted import path
import { fetchPublishers } from "../../api/publisherService";
import { hasRole, useActiveRoles } from "../../utils/permissionsUtils";
import { RoleEnum } from "../../types/RoleEnum";
import { fetchPublisherUpdateRequests } from "../../api/updatesService";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PublisherUpdatesList = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var activeRoles = useActiveRoles(ky);
    var _a = useState([]), publishersData = _a[0], setPublisherData = _a[1];
    var _b = useState(null), selectedPublisherUpdate = _b[0], setSelectedPublisherUpdate = _b[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get("detail");
        if (publisherId) {
            fetchPublishers(ky, { name: "", companyNumber: "", status: [] }).then(function (publishers) {
                var publisher = publishers.find(function (p) { return p.id === publisherId; });
                if (publisher) {
                    setSelectedPublisherUpdate(publisher);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(""), searchTerm = _d[0], setSearchTerm = _d[1];
    var isAnyFilterActive = function () {
        return true;
    };
    var _e = useQuery("publisherUpdates", function () { return fetchPublisherUpdateRequests(ky, "status=SUBMITTED"); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _f = _e.data, publisherUpdatesData = _f === void 0 ? [] : _f, isLoading = _e.isLoading, isError = _e.isError;
    useEffect(function () {
        if (publisherUpdatesData && publisherUpdatesData.length > 0) {
            var tmp = publisherUpdatesData
                .filter(function (update) {
                if (!update.publisher) {
                    return false;
                }
                return true;
            })
                .map(function (update) { return update.publisher; });
            setPublisherData(tmp);
        }
    }, [publisherUpdatesData]);
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var statusTranslations = {
        REQUEST: "Žádost",
        REJECTED: "Odmítnuto",
        ACTIVE: "Aktivní",
        CLOSING: "Uzavírání",
        CLOSED: "Uzavřeno",
    };
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, "0");
        var month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        var year = date.getFullYear();
        return "".concat(day, ".").concat(month, ".").concat(year);
    };
    var columns = [
        {
            name: "Nakladatel",
            key: "name",
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                // Remove any existing 'detail' query parameter
                currentParams.delete("detail");
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisherUpdate(item);
            },
        },
        {
            name: "Doplňek k názvu",
            key: "nameSuffix",
        },
        {
            name: "Kvalifikátor",
            key: "qualifier",
        },
        {
            name: "IČO",
            key: "companyNumber",
        },
        {
            name: "Datum narození",
            key: "birthDate",
            cellRenderer: function (item) {
                return item.birthDate ? formatDateToEuropean(item.birthDate) : "N/A";
            }, // Return 'N/A' if birthDate is undefined
        },
        {
            name: "Stav",
            key: "status",
            formatter: function (item) { return statusTranslations[item.status]; }, // Use Czech translations here
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return publisherUpdatesData; };
    source.resultCount = function () { return publisherUpdatesData.length; };
    source.totalCount = function () { return publisherUpdatesData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return (isError ? { message: "Error loading data" } : null); };
    source.failureMessage = function () { return "Error loading data"; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: "flex", height: "93.5vh" } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? "fill" : "100%", paneTitle: "Seznam aktualizac\u00ED nakladatele", paneSub: publisherUpdatesData.length > 0
                    ? "Nalezeno ".concat(publisherUpdatesData.length, " z\u00E1znam\u016F")
                    : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", firstMenu: !filterPaneIsVisible ? (React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? "Hide filters" : "Show filters", style: { marginLeft: "auto" } })) : (React.createElement(React.Fragment, null)) },
                React.createElement(TableComponent, { data: publishersData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source, searchTerm: searchTerm })),
            selectedPublisherUpdate ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail Aktualizace nakladatele", onClose: function () {
                    setSelectedPublisherUpdate(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete("detail");
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return history.push("/brpublishers/publisher-updates/approve/".concat(selectedPublisherUpdate.id));
                            }, fullWidth: true }, "Schv\u00E1lit")),
                        hasRole(activeRoles, RoleEnum.AGENCY_WORKER) && (React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                return history.push("/brpublishers/publisher-updates/reject/".concat(selectedPublisherUpdate.id));
                            }, fullWidth: true }, "Zam\u00EDtnout"))));
                } }, selectedPublisherUpdate ? (React.createElement(PublisherDetail, { publisher: selectedPublisherUpdate })) : (React.createElement("div", null, "No details available")))) : null)));
};
export default PublisherUpdatesList;
